import React, { useState, useRef } from 'react';
import VoiceButton from '../voiceButton/VoiceButton';
import TranscriptionDisplay from '../transcriptionDisplay/TranscriptionDisplay';

const BrainstormPage: React.FC = () => {
  const [sentences, setSentences] = useState<string[]>([]);
  const [currentTranscript, setCurrentTranscript] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const pauseTimeout = useRef<number | null>(null);
  const recognitionRef = useRef<any>(null);

  if (!recognitionRef.current) {
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.lang = 'pt-PT';
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;

    recognitionRef.current.onresult = (event: Event) => {
      const speechEvent = event as any;
      let newTranscript = '';

      for (let i = speechEvent.resultIndex; i < speechEvent.results.length; i++) {
        newTranscript += speechEvent.results[i][0].transcript;
      }

      if (pauseTimeout.current) {
        clearTimeout(pauseTimeout.current);
      }

      setCurrentTranscript(newTranscript);

      pauseTimeout.current = window.setTimeout(() => {
        if (newTranscript.trim() !== '') {
          // Check if the new transcript already exists in the sentences
          setSentences(prev => {
            const trimmedTranscript = newTranscript.trim();
            // If it doesn't exist, add it
            if (!prev.includes(trimmedTranscript)) {
              return [...prev, trimmedTranscript];
            }
            return prev; // If it exists, return previous state
          });
          setCurrentTranscript('');
        }
      }, 2000);
    };

    recognitionRef.current.onend = () => {
      setIsListening(false);
    };
  }

  const startListening = () => {
    setIsListening(true);
    recognitionRef.current.start();
  };

  const stopListening = () => {
    setIsListening(false);
    recognitionRef.current.stop();
    if (currentTranscript.trim() !== '') {
      setSentences(prev => {
        const trimmedTranscript = currentTranscript.trim();
        if (!prev.includes(trimmedTranscript)) {
          return [...prev, trimmedTranscript];
        }
        return prev; // If it exists, return previous state
      });
    }
  };

  const removeSentence = (sentenceToRemove: string) => {
    setSentences(prev => prev.filter(sentence => sentence !== sentenceToRemove));
  };

  return (
    <div className="brainstorm-page">
      <VoiceButton 
        onStartListening={startListening} 
        onStopListening={stopListening} 
        isListening={isListening} 
      />
      <TranscriptionDisplay 
        sentences={sentences} 
        currentTranscript={currentTranscript} 
        onRemoveSentence={removeSentence} 
      />
    </div>
  );
};

export default BrainstormPage;