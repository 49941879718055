import React from 'react';

const Footer: React.FC = () => {
  const currentYear: number = new Date().getFullYear();

  return (
    <section className="footer-section gridrowfull">
      <div className="footer-content col-12">
        <div className="footer-center col-12 col-t-12 col-d-12">
          <span>© {currentYear} Hugo Quintal. All Rights Reserved.</span>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/hugo-quintal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li>
              <a
                href="https://github.com/hugoquintal01"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;