import React from 'react';
import BrainstormPage from './components/brainstormPage/BrainstormPage'; // Import the main container component
import './style.css';
import Footer from './components/footer/Footer';
import Menu from './components/menu/Menu';

function App() {
  return (
    <div className="app">
      <Menu />
      <BrainstormPage />
      <Footer />
    </div>
  );
}

export default App;