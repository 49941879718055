import React, { useState, useEffect } from "react";

const Menu: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 10) { // adjust the value to your liking
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <section
            className={`menu-section col-12 ${scrolled ? "scrolled" : ""}`}
        >
            <a target="_blank" href="https://hugoquintal.pt">Hugo Quintal</a>
        </section>
    );
};

export default Menu;