import React from 'react';

const VoiceButton: React.FC<{ onStartListening: () => void, onStopListening: () => void, isListening: boolean }> = ({ onStartListening, onStopListening, isListening }) => {
  const handleClick = () => {
    if (isListening) {
      onStopListening();
    } else {
      onStartListening();
    }
  };

  return (
    <div className={`voice-button ${isListening ? 'active' : ''}`} onClick={handleClick}>
      <div className="voice-icon">
        🎤 {/* You can replace this with an actual microphone icon */}
      </div>
      <div className="recording-status">
        {isListening ? "Recording..." : "Click to Record"}
      </div>
    </div>
  );
};

export default VoiceButton;