import React from 'react';

interface TranscriptionDisplayProps {
  sentences: string[];
  currentTranscript: string;
  onRemoveSentence: (sentence: string) => void; // Update the prop type
}

const TranscriptionDisplay: React.FC<TranscriptionDisplayProps> = ({ sentences, currentTranscript, onRemoveSentence }) => {
  return (
    <div className="transcription-text">
      {/* Render each sentence as a separate <p> */}
      {sentences.map((sentence, index) => (
        <div key={index} className="sentence-container">
          <p>{sentence}</p>
          <button onClick={() => onRemoveSentence(sentence)}>🗑️</button> {/* Delete icon */}
        </div>
      ))}

      {/* Render the current ongoing transcription */}
      {currentTranscript && (
        <div className="current-transcription">
          <p>{currentTranscript}</p>
        </div>
      )}
    </div>
  );
};

export default TranscriptionDisplay;